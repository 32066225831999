<template>
  <div>
    <v-row>
    <v-text-field
      label="Local IPs"
      v-model="model"
      clearable
    ></v-text-field>
    <v-checkbox 
      v-if="checkboxOpen"
      label="lock"
      v-model="currentLock"
    />
    </v-row>

    <v-expansion-panels v-if="items.length > 1">
      <v-expansion-panel
      >
        <v-expansion-panel-header>Select one ip</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index"
              @click="selected(item)"
            >
              {{item}}
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import KeyBase from '@/components/edit/keys/KeyBase';
import SplitModel from '@/components/edit/keys/SplitModel';
export default {
  keyname: "IPs",
  props: ['bind'],
  mixins: [KeyBase, SplitModel],
}
</script>