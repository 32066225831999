<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Safekeeping of Key
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row 
        align="center"
      >
        <v-spacer/>
        <v-btn 
          x-small 
          class="grey--text"
          to="/docs/users-manual/binds/safekeeping"
        >
          guide
        </v-btn>
      </v-row>
      <v-form v-model="valid">
        <v-text-field
          label="Safekeeped Key"
          v-model="SafekeepedKey"
          clearable
          :rules="Rules"
        ></v-text-field>
      </v-form>
      <v-divider/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: ['bind'],
  data () {
    return {
      SafekeepedKey: "",
      valid: false,
      Rules: [
        v => v.length <= 128 || 'SafekeepedKey must be shorter than 128 characters'
      ],

    }
  },
  methods: {
    refreshBind(bind){
      console.log("SafekeepedKey - refreshBind", bind)

      this.SafekeepedKey = bind.SafekeepedKey
      console.log("this.SafekeepedKey",this.SafekeepedKey)
    },
    updateBind(updates, bind){
      console.log("this.SafekeepedKey",this.SafekeepedKey)
      console.log("bind.SafekeepedKey",bind.SafekeepedKey)
      if (this.valid && this.SafekeepedKey != bind.SafekeepedKey){
        // binds
        bind.SafekeepedKey = this.SafekeepedKey
        // updates
        updates["safekeepedkey"] = this.SafekeepedKey
      }
    },
  },
}
</script>