<template>
  <div>
    {{$route.params.bid}}
    <v-container>
      <v-row>
        <v-col>CID</v-col>
        <v-col>{{bind.Cid ? bind.Cid : ""}}</v-col>
      </v-row>
      <v-row>
        <v-col>PSN</v-col>
        <v-col>{{bind.Psn ? bind.Psn : ""}}</v-col>
      </v-row>
      <v-row>
        <v-col>Status</v-col>
        <v-col>{{bind.Status ? bind.Status : ""}}</v-col>
      </v-row>
    </v-container>
    <Preset ref="preset"></Preset>
    <!-- ref should be camel case -->
    <!-- https://qiita.com/Ancient_Scapes/items/b56472281ae905b15b46 -->
    <v-btn @click="$refs.preset.open()">げろよーん</v-btn>
  </div>
</template>

<script>
/* eslint no-unused-vars: 0 */
import axios from 'axios';
import * as firebase from 'firebase'  // cdn
//import firebase from "firebase/app" // npm
import "firebase/auth";

import Preset from '@/dialogs/bind/Preset' 

export default {
  data () {
    return {
      dialog: false,
      bind: "",
    }
  },
  components: {
    Preset,
  },
  methods: {
  },
  mounted(){
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken(/* forceRefresh */ true).then((idToken) => {
          /* eslint-disable no-unused-vars */
          axios
          .get(this.$koshinto + '/v1/GetBind/' + this.$route.params.bid + '/' + idToken)
          .then(response => {
            if (response.status == 200) {
              this.bind = response.data.bind;
              console.log("response = ", response.data)
            }
          });
        }).catch(function(error) {
          console.log(error)
          // Handle error
        });
      }
    });
  },
}
</script>