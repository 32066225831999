<template>
  <div>
    <Binds 
      :url="this.$koshinto + '/v1/getBindRequestWaitingBinds/%d/%d/%s'"
      name="Bind Request Waiting"
    />
  </div>
</template>

<script>
import Binds from '@/components/Binds'
export default {
  components: {
    Binds,
  }
}
</script>