<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Keys
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <v-row 
        align="center"
      >
        <v-spacer/>
        <v-btn 
          x-small
          class="grey--text"
          to="/docs/users-manual/binds/keys"
        >
          guide
        </v-btn>
      </v-row>

      <v-divider/>

      <Cid
        :bind='bind'
        ref="refCid"
      />

      <v-divider/>
      
      <Psn
        :bind='bind'
        ref="refPsn"
      />

      <v-divider/>

      <SecretString
        :bind='bind'
        ref="refSecretString"
      />

      <v-divider/>

      <Nic
        :bind='bind'
        ref="refNic"
      />

      <v-divider/>

      <IP
        :bind='bind'
        ref="refIP"
      />

      <v-divider/>

      <IPs
        :bind='bind'
        ref="refIPs"
      />

      <v-divider/>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Nic from '@/components/edit/keys/Nic'
import IPs from '@/components/edit/keys/IPs'
import IP from '@/components/edit/keys/IP'
import Cid from '@/components/edit/keys/Cid'
import Psn from '@/components/edit/keys/Psn'
import SecretString from '@/components/edit/keys/SecretString'
export default {
  props: ['bind'],
  components: {
    Nic,
    IPs,
    IP,
    Cid,
    Psn,
    SecretString,
},
  data () {
    return {
    }
  }, 
  methods: {
    refreshBind(bind){
      console.log("Keys - refreshBind", bind)
      for (const ref in this.$refs){
        const r = this.$refs[ref]
        if (r) { // not null
          try {
            r.refreshBind(bind)
          } catch (error) {
            console.log(error.message)
          }
        }
      }
    },
    updateBind(updates, bind){
      for (const ref in this.$refs){
        const r = this.$refs[ref]
        if (r) { // not null
          console.log("updateBind - Keys - ", ref)
          try {
            r.updateBind(updates, bind)
          } catch (error) {
            console.log(error.message)
          }
        }
      }
    }
  },
  updated: function(){
    console.log("Keys updated - bind:",this.bind)
    if (this.bind){
      // this.bind not null
      this.refreshBind(this.bind)
    }
  },

}
</script>