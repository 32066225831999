<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Sansi library Download
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <v-row 
      >
        <v-spacer/>
        <v-btn 
          x-small 
          class="grey--text"
          to="/docs/users-manual/binds/sansilibrarydownload"
        >
          guide
        </v-btn>
      </v-row>

      <v-row
      >

        <v-spacer/>
        <v-btn
          style="text-transform: none"
          class="ma-2"
          @click="download('arm', 'v1.3')"
        >
          arm Linux v1.3
        </v-btn>
        <v-spacer/>

      </v-row>
      <v-row
      >

        <v-spacer/>
        <v-btn
          style="text-transform: none"
          class="ma-2"
          @click="download('x86', 'v1.3')"
        >
          x86 Linux v1.3
        </v-btn>
        <v-spacer/>

      </v-row>
      <v-row
      >

        <v-spacer/>
        <v-btn
          style="text-transform: none"
          class="ma-2"
          @click="download('mac', 'v1.3')"
        >
          x86 Mac v1.3
        </v-btn>
        <v-spacer/>
      </v-row>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import * as firebase from 'firebase'  // cdn
//import firebase from "firebase/app" // npm
import "firebase/auth";
// https://github.com/alexei/sprintf.js
var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf

export default {
  props: ['bind'],
  methods: {
    download(arch, version){
      console.log("arch",arch, "version",version)
      var user = firebase.auth().currentUser;
      if (user) {
        user.getIdToken(/* forceRefresh */ true).then((idToken) => {
          const url = sprintf(this.$koshinto + '/v1/downloadArchive/%s/%s/%s/%s', this.bind.Bid, arch, version, idToken)
          // https://stackoverflow.com/a/56733073/11073131
          window.open(url)
        });
      }
    }
  }
}
</script>