<template>
  <div>
    <Binds 
      :url="$koshinto + '/v1/getOwnedBinds/%d/%d/%s'"
      name="All"
    />
  </div>
</template>

<script>
import Binds from '@/components/Binds'
export default {
  components: {
    Binds,
  }
}
</script>