<template>
  <div class="binds">
    <Edit ref="edit" :bind="binds[selectedItem]"></Edit>
    <v-card
      v-if="$isLogin && $isEmailVerified"
    >
      <v-list
        shaped
        dense
        style="max-height: 250px"
        class="overflow-y-auto"
      >
        <v-subheader> {{name}} Binds
          <v-spacer/>
          <v-btn 
            x-small
            class="grey--text"
            to="/docs/users-manual/binds/bindslist"
          >
            guide
          </v-btn>
        </v-subheader>

        <v-progress-linear
          v-if="progressLinear"
          indeterminate
          color="primary"
        ></v-progress-linear>

        <div class="text-center">
          <v-pagination
            v-if="totalPages >= 2"
            v-model="page"
            :length="totalPages"
            @input = "paginationSelected"
          ></v-pagination>
        </div>
        <v-list-item-group v-model="selectedItem" mandatory color="primary">
          <v-list-item
            v-for="bind in binds"
            :key="bind.Bid"
            @click="bindSelected(bind)"
          >
            <v-list-item-content>
              <v-list-item-title class="text-sm" v-text="bind.Bid" ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-btn
          absolute
          dark
          fab
          bottom
          right
          color="primary"
          @click="getBinds"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint no-unused-vars: 0 */
import * as firebase from 'firebase'  // cdn
//import firebase from "firebase/app" // npm
import "firebase/auth"
import axios from 'axios'
import Edit from '@/dialogs/binds/Edit'
// https://github.com/alexei/sprintf.js
var sprintf = require('sprintf-js').sprintf,
    vsprintf = require('sprintf-js').vsprintf

export default {
  components: {
    Edit,
  },
  props: [
    'url',  // for get binds
    'name', // Display name
  ],
  data: function () {
    return {
      // binds
      binds: "",
      totalNumber: 0,

      // for v-pagination, getBind()
      page:          1, // current page (start from 1, not 0)
      itemsPerPage: 10, // number of items a page

      // progress-linear on/off
      progressLinear: false,

      // for v-list-item-group
      selectedItem: "", // index of binds which is selected

    }
  },
  methods: {
    // pagenation
    paginationSelected: function(page){
      console.log("page " + page)
      console.log("offset "+ (page -1)*this.itemsPerPage)
      this.getBinds()
      console.log("binds " + this.binds)
    },

    // bind
    bindSelected: function(bind){
      this.$refs.edit.open()
//      this.$refs.edit.refreshBind(bind) // not working here!
    },
    getBinds: function() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken(true).then((idToken) => {
            const url = sprintf(this.url, 
                                this.itemsPerPage,
                                (this.page -1)*this.itemsPerPage, idToken)

            this.progressLinear = true; // start progressLinear 
            console.log("url:",url)

            // get bind
            axios
            .get(url)
            .then(response => {
              if (response.status == 200) {
                console.log("status = ", response.status)

                // for binds
                this.totalNumber = response.data.totalNumber;
                this.binds = response.data.binds;

                // stop progress_linear
                this.progressLinear = false; // stop progressLinear

                console.log(this.binds)
              }
            });
          });
        }
      });
    },
  },
  computed: {
    totalPages: function(){
      return Math.ceil(this.totalNumber/this.itemsPerPage)
    }
  },
  mounted(){
    this.getBinds()
  },
}
</script>