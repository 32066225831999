const bitPsn          =  1
const bitCid          =  2
const bitSecretString =  4
const bitNic          =  8
const bitLocalIP      = 16
const bitGlobalIP     = 32

export default {
  data() {
    return {
      bindwithField: {
        Psn: bitPsn,
        Cid: bitCid,
        SecretString: bitSecretString,
        Nic: bitNic,
        IPs: bitLocalIP,
        IP: bitGlobalIP,
      },
      model: this.bind ? this.bind[name] : "",
      currentLock: false,
      originalLock: false,
    }
  },
  methods: {
    // for $ref
    refreshBind(bind){
      const keyname = this.keyname
      console.log("refreshBind", keyname, this.bindwithField[keyname], bind)
      this.model = bind[keyname]

      this.originalLock = bind.BindWith & this.bindwithField[keyname] ? true: false
      this.currentLock  = this.originalLock
      console.log("refreshBInd - originalLock", this.originalLock)
      console.log("refreshBInd - currentLock", this.currentLock)

      // call function on the mixin base
      this.postRefreshBind(bind)    
    },
    updateBind(updates, bind){
      console.log("updateBind - ", keyname)
      const keyname = this.keyname
      // Key
      if (this.model != bind[keyname]){
        // bind
        bind[keyname] = this.model
        // updates
        updates[keyname.toLowerCase()] = this.model
      }
      if (this.currentLock){
        updates["bindwith"] += this.bindwithField[keyname]
      }
    },
  },
  computed: {
    keyname(){
      return this.$options.keyname
    },
  },
  mounted() {
    this.refreshBind(this.bind)
  }
}