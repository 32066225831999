<template>
    <v-row>
    <v-text-field
      label="SD Card Serial ID"
      v-model="model"
      clearable
    ></v-text-field>
    <v-checkbox
      label="lock"
      v-model="currentLock"
    />
  </v-row>
</template>

<script>
import KeyBase from '@/components/edit/keys/KeyBase'
export default {
  keyname: "Cid",
  props: ['bind'],
  mixins: [KeyBase],
  data () {
    return {
    }
  },
  methods: {
    // for BindWith mixin
    postRefreshBind(bind){ /* eslint no-unused-vars: 0 */

    }
  }
}
</script>